// Favicon
import Fav_Image from '@/components/Layout/assets/favicon.png';
export const INVITATO_FAVICON = Fav_Image;

// BG Image Design
export const BG_WISHLIST = `https://ik.imagekit.io/adelinejune/michaelselvy/10.%20Background%20Ucapan.jpg?updatedAt=1698900729491`;
export const BG_RSVP = `https://ik.imagekit.io/adelinejune/michaelselvy/9.%20Background%20RSVP.jpg?updatedAt=1698900729125`;
export const BG_COVER = `https://ik.imagekit.io/adelinejune/michaelselvy/REVISI%20FOTO%20COVER.JPG?updatedAt=1699254134582`;
export const BG_FOOTER = `https://ik.imagekit.io/adelinejune/michaelselvy/14.%20Foto%20Footer%20Potrait.jpg?updatedAt=1698900729504`;
export const BG_GIFTCARD = `https://ik.imagekit.io/adelinejune/michaelselvy/16.%20Wedding%20Gift.jpg?updatedAt=1698924979672`;
export const BG_COUNTING = `https://ik.imagekit.io/adelinejune/michaelselvy/13.%20Foto%20Background%20Landscape%20Counting%20Days.jpg?updatedAt=1698900729322`;
export const BG_DETAIL = `https://ik.imagekit.io/invitatoid/template-naomirezky/bg-detail_4RGKk2WOV.jpg?updatedAt=1638917135419`;
export const BG_START_JOURNEY = `https://ik.imagekit.io/adelinejune/michaelselvy/20.%20Love%20Story.jpg?updatedAt=1698924981074`;
export const BG_LOVESTORY = BG_WISHLIST;
export const BG_FILTER = `https://ik.imagekit.io/adelinejune/michaelselvy/COVER%20MICHAEL%20SELVY.jpg?updatedAt=1699271278688`;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/adelinejune/michaelselvy/15.%20Photo%20Desktop.jpg?updatedAt=1698900877763`;

// The Bride Photo
export const IMG_MAN = `https://ik.imagekit.io/adelinejune/michaelselvy/8.%20Photo%20Groom%20Potrait.jpg?updatedAt=1698900614062`;
export const IMG_GIRL = `https://ik.imagekit.io/adelinejune/michaelselvy/7.%20Photo%20Bride%20Potrait.jpg?updatedAt=1698900614396`;

// Cover Gallery
export const COVER_GALLERY = `https://ik.imagekit.io/adelinejune/michaelselvy/11.%20Foto%20Cover%20Galeri%20Pengantin%201.jpg?updatedAt=1698900729289`;
export const COVER_GALLERY_2 = `https://ik.imagekit.io/adelinejune/michaelselvy/12.%20Foto%20Cover%20Galeri%20Pengantin%202.jpg?updatedAt=1698900729485`;
export const COVER_GALLERY_3 = `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/8.jpg?updatedAt=1698902014171`;

// Image Fade Slide Show
export const SLIDE_1 = `https://ik.imagekit.io/adelinejune/michaelselvy/2.%20Photo%20Slideshow%20A.jpg?updatedAt=1698900423714`;
export const SLIDE_2 = `https://ik.imagekit.io/adelinejune/michaelselvy/3.%20Photo%20Slideshow%20B.jpg?updatedAt=1698900423650`;
export const SLIDE_3 = `https://ik.imagekit.io/adelinejune/michaelselvy/4.%20Photo%20Slideshow%20C.jpg?updatedAt=1698900423680`;
export const SLIDE_4 = `https://ik.imagekit.io/adelinejune/michaelselvy/5.%20Photo%20Slideshow%20D.jpg?updatedAt=1698900423526`;
export const SLIDE_5 = `https://ik.imagekit.io/adelinejune/michaelselvy/6.%20Photo%20Slideshow%20E.jpg?updatedAt=1698900423467`;

// Dont Change With CDN Image
import ImgQRCover from '../images/qrcover.jpg';
export const IMG_QR_COVER = ImgQRCover;
